import { load } from "@cashfreepayments/cashfree-js";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";

const CardLessEMI = ({
  providerName,
  providerImg,
  providerText,
  providerHeading,
  paymentId,
  payRef,
  collect_request_id,
  childComponentFunctionRef,
  finalAmountWithMDR,
  setOpenWindow,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const isValidPhoneNumber = /^[6-9]\d{9}$/.test(phoneNumber);

  useEffect(() => {
    payRef.current.disabled = true;
    if (isValidPhoneNumber) payRef.current.disabled = false;
  }, [phoneNumber]);

  useEffect(() => {
    finalAmountWithMDR("cardless emi", providerName);
  }, [providerName]);

  const handleSubmit = async () => {
    if (!isValidPhoneNumber) {
      toast.error("Please enter valid Phone Number");
      return;
    }
    const cashfree = await load({
      mode: process.env.REACT_APP_CASHFREE_MODE,
    });

    let cl = cashfree.create("cardlessEMI", {
      values: {
        provider: providerName,
        phone: phoneNumber,
        buttonText: providerName,
        buttonIcon: true,
      },
    });
    cl.on("loaderror", function (data) {});

    initPay(cl, cashfree);
    cl.mount("#" + providerName);
  };
  function initPay(cl, cashfree) {
    cl.on("ready", (d) => {
      cashfree
        .pay({
          paymentMethod: cl,
          paymentSessionId: paymentId,
          returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,
        })
        .then(function (data) {
          // cl.enable();
          if (data.error) {
            toast.error(data.error.message);
          }
        });
    });

    cl.on("error", (error) => {
      toast.error(error.message);
    });
  }

  childComponentFunctionRef.current = handleSubmit;

  return (
    <div className="flex flex-col gap-2 mb-3">
      <div className="flex  ml-3 gap-x-2 mt-4 pr-2">
        <div className="opacity-0 w-0 h-0" id={providerName}></div>
        <div className="flex justify-center items-center w-20 h-10 shadow-black-shadow rounded-lg  ">
          <img src={providerImg} className="w-14 bg-white" alt="paytm" />
        </div>
        <div className="flex flex-col">
          <p className=" text-[12px] text-black font-semibold ">
            {providerHeading}
          </p>
          <p className=" text-[9px] text-[#717171]">
            {providerText || "link your paytm account"}
          </p>
        </div>
        <p
          onClick={() => setOpenWindow(false)}
          className="text-[9px] underline text-[#505E8F] ml-auto cursor-pointer"
        >
          Close
        </p>
      </div>
      <div className="flex items-center bg-white shadow-black-shadow rounded-lg py-2 px-2 ml-5 mr-5 mt-2 w-auto">
        <div className="text-[12px] mr-2 ml-1">+91</div>
        <input
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
          value={phoneNumber}
          type="text"
          name="phoneNo"
          id="ph"
          className="  pl-1 outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-xs"
          placeholder="Phone Number"
          maxLength="10"
          inputMode="numeric"
        />
        {isValidPhoneNumber && <img src={tick} className="m-1" alt="tick" />}
        {!isValidPhoneNumber && (
          <img src={fadedTick} className="m-1" alt="faded" />
        )}
      </div>
    </div>
  );
};

export default CardLessEMI;
