import React, { useState } from "react";

function FeesBreakdown({ data }: any) {
  const [open, setOpen] = useState(false);

  return (
    <div className="pb-2 pr-2 border-b ">
      <p
        onClick={() => setOpen(!open)}
        className="text-[8px] cursor-pointer text-right text-[#717171]"
      >
        View Breakdown
      </p>

      <div
        className={
          "duration-150 transition-all w-[65%] ml-auto bg-gray-100 rounded-md " +
          (open ? " h-auto p-2" : " h-0 p-0")
        }
      >
        {open && (
          <div className="w-full space-y-1 ">
            <div className="flex border-b border-dotted   border-black  justify-between items-center">
              <h5 className=" text-sm font-bold">Summary</h5>
              <h5
                onClick={() => setOpen(false)}
                className="cursor-pointer font-bold text-xs text-black"
              >
                X
              </h5>
            </div>
            <div className="flex justify-between items-center">
              <h5 className=" font-bold text-xs text-[#717171]">
                Order Amount
              </h5>
              <h5 className=" font-bold text-xs text-black">
                ₹{data?.baseRate.toFixed(2)}
              </h5>
            </div>
            <div className="flex justify-between items-center">
              <h5 className=" font-bold text-xs text-[#717171]">
                Convenience Fee
              </h5>
              <h5 className=" font-bold text-xs text-black">
                ₹{data?.convenienceFee.toFixed(2)}
              </h5>
            </div>
            <div className="flex justify-between items-center">
              <h5 className=" font-bold text-xs text-[#717171]">GST</h5>
              <h5 className=" font-bold text-xs text-black">
                ₹{data?.gst?.toFixed(2)}
              </h5>
            </div>
            <div className="flex border-t border-black justify-between items-center">
              <h5 className=" font-bold text-xs text-black">Total</h5>
              <h5 className=" font-bold text-xs text-black">
                ₹{data?.total?.toLocaleString("hi")}
              </h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeesBreakdown;
