import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "./pages/StatusPage/Success";
import Failure from "./pages/StatusPage/Failure";
import OrderFailed from "./pages/StatusPage/NotFound";
import Sdk from "./pages/StatusPage/Sdk";

import ReturnPage from "./pages/StatusPage/ReturnPage";
import Ccavenue from "./pages/Ccavenue/Ccavenue";
import Callback from "./pages/Ccavenue/Callback";
import Expier from "./pages/StatusPage/Expier";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/payment-success" element={<Success />} />
        <Route path="/ccavenue" element={<Ccavenue />} />
        <Route path="/ccavenue-callback" element={<Callback />} />
        <Route path="/payment-failure/*" element={<Failure />} />
        <Route path="/payment-expire/*" element={<Expier />} />
        <Route path="/order-notfound" element={<OrderFailed />} />
        <Route path="/collect-sdk-payments/*" element={<Sdk />} />
        <Route path="/return-page/*" element={<ReturnPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
