import React, { useEffect, useState } from "react";
import bottomLogo from "./assets/bottomLogo.svg";
import "./App.css";
import HomeScreen from "./pages/HomeScreen/HomeScreen";

import "react-toastify/dist/ReactToastify.css";
import useDeviceDetect from "./hooks/useDeviceDetect";
// import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";
function App() {
  const [count, setCount] = useState(0);
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 70);

    return () => clearInterval(interval);
  }, [count]);
  return (
    <>
      {/* {count >= 100 ? ( */}
      <div
        className={
          "mx-auto h-full relative font-poppins] " +
          (isMobile ? " w-full" : " max-w-[378px]")
        }
      >
        <div
          className={`shadow-[0px_6px_6px_0px_rgba(0,_0,_0,_0.2)] flex flex-col  scrollbar-hide h-full rounded-md overflow-hidden bg-[#FBFDFF]  ${isMobile ? " min-h-screen" : " min-h-[70vh]"}`}
        >
          <HomeScreen />
        </div>
      </div>
      {/* ) : (
        <LoadingScreen count={count} />
      )} */}
    </>
  );
}

export default App;
