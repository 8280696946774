import React, { useEffect, useState, useRef } from "react";
import sbiBank from "../../assets/sbi.svg";
import hdfcBank from "../../assets/hdfc.svg";
import axis from "../../assets/axisabnk.svg";
import { load } from "@cashfreepayments/cashfree-js";
import {
  Banks,
  defaultEasebuzzBanks,
  EasebuzzBanks,
} from "../../utils/bankData";
import Select from "react-select";
import { toast } from "react-toastify";
import { LiaUniversitySolid } from "react-icons/lia";

function NetBanking({
  childComponentFunctionRef,
  paymentId,
  payRef,
  collect_request_id,
  finalAmountWithMDR,
  isBlank,
  isBuzz,
  easebuzzPaymentId,
  isEasebuzzSchool,
  setOpenWindow,
}) {
  const selectRef = useRef(null);
  const [cashfree, setCashfree] = useState(null); // State to store Cashfree instance
  const [paymentStatus, setPaymentStatus] = useState("");
  const [dropdownHeight, setDropdownHeight] = useState(12);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const tempSessionId =
    "session_VWOh6OB8VmV90L6AeRY5zVFKYAgKWXcTx3mC-Q7_AtVGIvouEnr6l8iW0NudElhjY09hXwYJa1aKG8RcTg0uV5-9ANSWbLJAJsCD7eyZwghh";
  // State to manage the selected bank
  const [selectedBank, setSelectedBank] = useState("");
  const [bankname, setbankname] = useState("");
  const [isBBMESchool, setIsBBMESchool] = useState("");
  useEffect(() => {
    const loadCashfree = async () => {
      const cf = await load({ mode: process.env.REACT_APP_CASHFREE_MODE }); // Change to 'production' for live
      setCashfree(cf);
    };
    loadCashfree();
    const fetchScoolId = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_PAYMENT_BACKEND}/edviron-pg/school-id?collect_id=${collect_request_id}`
        );
        const data = ["66925d9e309122e6efa60a7d"].includes(await res.text());
        setIsBBMESchool(data);
      } catch (error) {}
    };
    fetchScoolId();
  }, []);

  useEffect(() => {
    payRef.current.disabled = true;
    if (selectedBank !== "") payRef.current.disabled = false;
  }, [selectedBank]);
  const handleButtonClick = () => {
    // Check if the Select component is mounted
    if (selectRef.current) {
      // Focus on the Select component to simulate a click
      selectRef.current.focus();
    }
  };
  const selectedBanks = [
    "ICICI Bank",
    "Axis Bank",
    "Yes Bank Ltd",
    "State Bank Of India",
    "HDFC Bank",
  ];
  const handleBankChange = (event) => {
    finalAmountWithMDR("netbanking", event.label);
    if (isBBMESchool && event.label === "ICICI Corporate Netbanking") {
      setSelectedBank("ICICICO");
      setbankname("ICICI Corporate Netbanking");
    } else {
      if (isBuzz === "true") {
        setSelectedBank(event.value);
        setbankname(event.label);
      } else {
        finalAmountWithMDR("netbanking", event.label);
        setSelectedBank(event.value);
        setbankname(event.label);
      }
    }
  };

  let banksName = isEasebuzzSchool ? defaultEasebuzzBanks : Banks;
  // if (isBBMESchool || isEasebuzzSchool) {
  //   banksName = EasebuzzBanks;
  // }
  const options = banksName.map((bank) => {
    const value = isEasebuzzSchool ? bank.value : bank.abbreviation;
    return { value: value, label: bank.name };
  });

  const payNow = () => {
    try {
      document.getElementById("paymentForm").submit();
    } catch (error) {
      console.error("Error paying", error);
    }
  };

  const handleSubmit = () => {
    if (isEasebuzzSchool || (isBBMESchool && selectedBank === "ICICICO")) {
      payNow();

      //  else {
      //   if (!selectedBanks.includes(bankname)) {
      //     payNow();
      //   } else {
      //     const netbanking = cashfree.create("netbanking", {
      //       values: {
      //         netbankingBankName: selectedBank,
      //         buttonText: `${selectedBank} Bank`,
      //         buttonIcon: true,
      //       },
      //       classes: {
      //         complete: "empty_div",
      //       },
      //     });
      //     netbanking.on("loaderror", (data) => {
      //       toast.error(data.error.message);
      //       setPaymentStatus("Error loading Pay Later component");
      //     });

      //     netbanking.on("ready", (d) => {
      //       cashfree
      //         .pay({
      //           paymentMethod: netbanking,
      //           paymentSessionId: paymentId,
      //           redirectTarget: isBlank === "true" ? "_blank" : "_self",
      //           returnUrl:
      //             process.env.REACT_APP_RETURN_URL + collect_request_id, // change this with redirect url,
      //         })
      //         .then(function (data) {
      //           if (data.error) {
      //             toast.error(data.error.message);
      //           }
      //         });
      //     });
      //     netbanking.mount("#netbanking");
      //   }
      // }
    } else {
      const netbanking = cashfree.create("netbanking", {
        values: {
          netbankingBankName: selectedBank,
          buttonText: `${selectedBank} Bank`,
          buttonIcon: true,
        },
        classes: {
          complete: "empty_div",
        },
      });
      netbanking.on("loaderror", (data) => {
        toast.error(data.error.message);
        setPaymentStatus("Error loading Pay Later component");
      });

      netbanking.on("ready", (d) => {
        cashfree
          .pay({
            paymentMethod: netbanking,
            paymentSessionId: paymentId,
            redirectTarget: isBlank === "true" ? "_blank" : "_self",
            returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id, // change this with redirect url,
          })
          .then(function (data) {
            if (data.error) {
              toast.error(data.error.message);
            }
          });
      });
      netbanking.mount("#netbanking");
    }

    // else {
    //   const netbanking = cashfree.create("netbanking", {
    //     values: {
    //       netbankingBankName: selectedBank,
    //       buttonText: `${selectedBank} Bank`,
    //       buttonIcon: true,
    //     },
    //     classes: {
    //       complete: "empty_div",
    //     },
    //   });
    //   netbanking.on("loaderror", (data) => {
    //     toast.error(data.error.message);
    //     setPaymentStatus("Error loading Pay Later component");
    //   });

    //   netbanking.on("ready", (d) => {
    //     cashfree
    //       .pay({
    //         paymentMethod: netbanking,
    //         paymentSessionId: paymentId,
    //         redirectTarget: isBlank === "true" ? "_blank" : "_self",
    //         returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id, // change this with redirect url,
    //       })
    //       .then(function (data) {
    //         if (data.error) {
    //           toast.error(data.error.message);
    //         }
    //       });
    //   });
    //   netbanking.mount("#netbanking");
    // }
  };
  const handlebankClick = (bankName, mountPoint) => {
    const bankPayment = cashfree.create("netbanking", {
      values: {
        netbankingBankName: bankName,
        buttonText: `${bankName} Bank`,
        buttonIcon: true,
      },
      classes: {
        complete: "empty_div",
      },
    });
    bankPayment.on("loaderror", (data) => {
      console.error("Load error:", data.error, data.value);
      toast.error(data.error.message);
      setPaymentStatus("Error loading Pay Later component");
    });

    bankPayment.on("ready", (d) => {
      cashfree
        .pay({
          paymentMethod: bankPayment,
          paymentSessionId: paymentId,
          redirectTarget: isBlank === "true" ? "_blank" : "_self",

          returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,
        })
        .then(function (data) {
          if (data.error) {
            toast.error(data.error.message);
          }
        });
    });
    bankPayment.mount("#netbanking");
  };
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
    setSelectedBank("");
    setbankname("");
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const bankPay = (code) => {
    try {
      const bcode = (document.getElementById("bankCode").value = code);
      document.getElementById("bankform").submit();
    } catch (error) {
      console.error("Error paying", error);
    }
  };
  childComponentFunctionRef.current = handleSubmit;
  return (
    <div>
      <div>
        <div>
          <div className="flex p-4 gap-x-4">
            <div className="w-auto px-3 h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg">
              <div className="flex text-[#717171] items-center">
                <LiaUniversitySolid className="text-2xl" />
                <h6 className=" text-[10.5px]  font-medium ml-2">All Banks</h6>
              </div>
            </div>
            <div className="flex flex-col justify-center items-start m-1 ">
              <p className="text-[12px] text-black font-semibold">
                Net banking
              </p>
              <p className="text-[9px] text-[#717171]">
                Enter Bank Account details{" "}
              </p>
            </div>
            <p
              onClick={() => setOpenWindow(false)}
              className="text-[9px] underline text-[#505E8F] ml-auto cursor-pointer"
            >
              Close
            </p>
          </div>
          <div className="p-4 pb-0 h-auto max-h-96">
            <div className="flex flex-col w-full h-full">
              <label
                className="font-semibold text-[12px] pl-2"
                htmlFor="bankDropdown"
              >
                Choose bank:
              </label>
              <Select
                className=" font-normal p-2 rounded-lg"
                id="bankDropdown"
                //ref={selectRef}
                value={selectedBank}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                onChange={handleBankChange}
                options={options}
                isSearchable
                placeholder={bankname ? bankname : "Search bank"}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#E8EBF6",
                    border: "none",
                  }),
                  input: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent",
                    "::placeholder": {
                      opacity: 1,
                    },
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    fontSize: "10px",
                  }),
                }}
              />
            </div>
          </div>
          {!selectedBank && (
            <div
              id="default"
              className={`flex gap-x-4 py-4 overflow-hidden overflow-x-scroll scrollbar-hide ${
                menuIsOpen ? "h-80" : "h-auto"
              }  pl-7`}
            >
              {/*<div
              onClick={() => handlebankClick("SBINR", "mountsbi")}
              className="h-[40px] min-w-[140px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
            >
              <img className="w-6 mr-2" src={sbiBank} alt="SBI" />
              <h6 className=" text-xs text-[#717171] font-medium">SBI</h6>
            </div>
            <div
              id="mounthdfc"
              onClick={() => {
                handlebankClick("HDFCR", "mounthdfc");
              }}
              className="h-[40px] min-w-[140px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
            >
              <img className="w-6 mr-2" src={hdfcBank} alt="HDFC" />
              <h6 className=" text-xs text-[#717171] font-medium">HDFC</h6>
            </div>}
              {/*<div>
                <button id="mountsbi">
                  <img
                    className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
                    alt="SBI"
                  />
                </button>
              </div>*/}
              {/*<div>
                <div className="flex items-center h-full w-full p-0">
                  <button id="mounthdfc">
                    <img
                      className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
                      src={hdfcBank}
                      alt="HDFC"
                    />
                  </button>
                </div>
              </div>*/}
              {/*<label htmlFor="bankDropdown">
              <div
                onClick={() => handlebankClick("UTIBR", "mountaxis")}
                className="h-[40px] min-w-[140px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img className="p-3" src={axis} alt="axis" />
              </div>
              <div>
                <div
                  className="flex items-center h-full w-full p-0"
                  onClick={handleButtonClick}
                >
                  <button>
                    <img
                      className="w-[140px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
                      src={other}
                      alt="HDFC"
                    />
                  </button>
                </div>
              </div>
            </label>*/}
            </div>
          )}
        </div>
      </div>
      <div id="">
        <div className="flex justify-center align-center items-center flex-col">
          <form
            id="paymentForm"
            method="POST"
            action="https://pay.easebuzz.in/initiate_seamless_payment/"
            className="hide"
          >
            <input
              type="hidden"
              key="access_key"
              name="access_key"
              value={easebuzzPaymentId}
              className="bg-red-500"
            />
            <input
              type="hidden"
              name="payment_mode"
              key="payment_mode"
              value="NB"
            />
            <input
              type="hidden"
              name="bank_code"
              key="bank_code"
              value={selectedBank}
            />
          </form>
        </div>
      </div>

      <div id="">
        <div className="flex justify-center align-center items-center flex-col">
          <form
            id="bankform"
            method="POST"
            action="https://pay.easebuzz.in/initiate_seamless_payment/"
            className="hide"
          >
            <input
              type="hidden"
              key="access_key"
              name="access_key"
              value={easebuzzPaymentId}
              className="bg-red-500"
            />
            <input
              type="hidden"
              name="payment_mode"
              key="payment_mode"
              value="NB"
            />
            <input
              type="hidden"
              name="bank_code"
              key="bank_code"
              id="bankCode"
            />
          </form>
        </div>
      </div>
      <div id="netbanking"></div>
    </div>
  );
}
export default NetBanking;
